import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Block, SectionHeader, SectionCopy } from '../../components';
import Card from './ProductCardSimple';
import { breakpoints } from '../../styles';

const QUERY = graphql`
  query {
    allShopifyProduct(sort: { fields: title, order: ASC }, limit: 4) {
      edges {
        ...ShopifyProducts
      }
    }
  }
`;

const Section = styled.section``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  padding-bottom: 2rem;
  & > :nth-child(3),
  & > :nth-child(4) {
    display: none;
  }
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    & > :nth-child(3) {
      display: block;
    }
  }
  @media (min-width: 1100px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    & > :nth-child(4) {
      display: block;
    }
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  padding: 0 2rem;
  @media (min-width: ${breakpoints.medium}px) {
    text-align: left;
    margin-bottom: 6rem;
  }
`;

const FeaturedProducts = () => {
  //
  const {
    allShopifyProduct: { edges: products },
  } = useStaticQuery(QUERY);

  return (
    <Section>
      <Container modifiers={['tightMargin']}>
        <Block>
          <Title>
            <SectionHeader modifiers={['small']}>
              <h2>Featured Products</h2>
            </SectionHeader>
            <SectionCopy>
              Our specialized fertilizers have helped farmers improve on yield and crop quality for the last 30 years.
            </SectionCopy>
          </Title>
          <Grid>
            {products.map(product => {
              return <Card key={product.node.id} data={product.node} />;
            })}
          </Grid>
        </Block>
      </Container>
    </Section>
  );
};

export default FeaturedProducts;
