import React from 'react';
import styled from 'styled-components';
import SoilImage from './SoilImage';
import QualityImage from './QualityImage';
import YieldImage from './YieldImage';
import ChilliImage from './ChilliImage';
import { breakpoints, defaultTheme } from '../../styles';
import { SectionItemHeader, SectionItemCopy } from '../../components';

const Section = styled.section`
  text-align: center;
  @media (min-width: ${breakpoints.medium}px) {
    text-align: left;
  }
  @media (min-width: ${breakpoints.large}px) {
    padding: 7.5rem 0;
  }
  @media (min-width: ${breakpoints.maxWidth}px) {
    padding: 7.5rem 0 15rem;
  }
`;

const Grid = styled.div`
  max-width: ${breakpoints.maxWidth}px;
  margin: 0 auto 0;
  #yield-image {
    grid-area: yieldImage;
  }
  #yield-copy {
    grid-area: yieldCopy;
    background: ${defaultTheme.black};
  }
  #soil-image {
    grid-area: soilImage;
  }
  #soil-copy {
    grid-area: soilCopy;
    background: ${defaultTheme.yellow};
  }
  #quality-image {
    grid-area: qualityImage;
  }
  #quality-copy {
    grid-area: qualityCopy;
  }
  #chilli-image {
    grid-area: chilliImage;
  }
  .grid-image {
    height: 272px;
  }
  .grid-copy,
  .grid-copy-borderless {
    position: relative;
    padding: 5rem 2rem 5rem;
  }
  display: grid;
  grid-template-areas:
    'yieldImage'
    'yieldCopy'
    'soilImage'
    'soilCopy'
    'qualityImage'
    'qualityCopy';
  @media (min-width: ${breakpoints.medium}px) {
    grid-template-areas:
      'yieldCopy yieldImage'
      'soilImage soilCopy'
      'qualityCopy qualityImage';
    grid-template-rows: 384px 384px 384px;
    .grid-image {
      height: 100%;
    }
    .grid-copy,
    .grid-copy-borderless {
      padding: 2rem 3rem 2rem;
      display: grid;
      align-items: center;
    }
  }
  @media (min-width: ${breakpoints.maxWidth}px) {
    grid-template-rows: 240px 240px;
    grid-template-columns: 338px 230px 346px 238px;
    grid-template-areas:
      'yieldCopy yieldCopy yieldImage chilliImage'
      'soilCopy soilImage qualityCopy qualityImage';
    grid-gap: 1rem;
    .grid-image {
      box-shadow: var(--elevation-3);
    }
    .grid-copy {
      padding: 2rem 3.5rem 2rem;
    }
  }
`;

const OptionalImageWrapper = styled.div`
  display: none;
  @media (min-width: ${breakpoints.large}px) {
    display: block;
  }
`;

const Benefits = () => {
  //
  return (
    <Section>
      <Grid>
        <div id="yield-image">
          <div className="grid-image">
            <YieldImage />
          </div>
        </div>
        <div id="yield-copy" className="grid-copy">
          <div>
            <SectionItemHeader modifiers={['onDark']}>
              <h2>Improve crop yield</h2>
            </SectionItemHeader>
            <SectionItemCopy modifiers={['onDark']}>
              High quality natural organic fertilizers and bio pesticides to help you produce higher organic & natural
              yields like never before.
            </SectionItemCopy>
          </div>
        </div>
        <div id="soil-image">
          <div className="grid-image">
            <SoilImage />
          </div>
        </div>
        <div id="soil-copy" className="grid-copy">
          <div>
            <SectionItemHeader>
              <h2>Improve soil</h2>
            </SectionItemHeader>
            <SectionItemCopy>
              Over utilized soil can be revived. Crops can contain more nutrients. Help plants overcome drought stress.
            </SectionItemCopy>
          </div>
        </div>
        <div id="quality-image">
          <div className="grid-image">
            <QualityImage />
          </div>
        </div>
        <div id="quality-copy" className="grid-copy-borderless">
          <div>
            <SectionItemHeader>
              <h2>Improve crop quality</h2>
            </SectionItemHeader>
            <SectionItemCopy>
              Produce animal meat that are tastier, healthier and contain an abundance of Omega 3.
            </SectionItemCopy>
          </div>
        </div>
        <OptionalImageWrapper id="chilli-image">
          <ChilliImage />
        </OptionalImageWrapper>
      </Grid>
    </Section>
  );
};

export default Benefits;
