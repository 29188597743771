import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '../components';
import Hero from '../page-components/home/Hero';
import Benefits from '../page-components/home/Benefits';
import How from '../page-components/home/How';
import FeaturedProducts from '../page-components/home/FeaturedProducts';

const Home = ({ location: { pathname } }) => {
  return (
    <>
      <SEO
        title="Sustainable and rapid growth for your crops"
        description="Sustainable and rapid growth for your crops"
      />
      <Hero />
      <FeaturedProducts />
      <Benefits />
      <How />
    </>
  );
};

export default Home;

Home.propTypes = {
  location: PropTypes.object.isRequired,
};
