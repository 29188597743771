import React from 'react';
import styled from 'styled-components';
import { PrimaryButtonGatsby, SecondaryButtonAnchor, SectionHeader, HeroCopy } from '../../components';
import HeroImage from './HeroImage';
import { breakpoints } from '../../styles';
import Chevron from '../../components/Svg/Chevron';
import { useSiteMetadata } from '../../utils';

const Section = styled.section`
  padding-top: 5rem;
`;

const Grid = styled.div`
  text-align: center;
  display: grid;
  grid-template-areas:
    'image'
    'copy';
  #hero-grid-1 {
    grid-area: image;
    height: 304px;
  }
  #hero-grid-2 {
    grid-area: copy;
    padding: 4rem 0 4rem;
    position: relative;
  }
  @media (min-width: ${breakpoints.medium}px) {
    text-align: left;
    #hero-grid-1 {
      height: 408px;
    }
    #hero-grid-2 {
      padding: 6.25rem 0 6.25rem;
    }
  }
  @media (min-width: ${breakpoints.large}px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'copy image';
    #hero-grid-1 {
      height: 528px;
    }
  }
`;

const CopyWrapper = styled.div`
  max-width: 764px;
  padding: 0 2rem 0;
  margin: 0 auto 0;
  @media (min-width: ${breakpoints.large}px) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 3rem 0 2rem;
    max-width: 632px;
  }
`;

const PrimaryCTA = styled(PrimaryButtonGatsby)`
  margin-bottom: 1rem;
`;

const SecondaryCTA = styled(SecondaryButtonAnchor)`
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  svg {
    transition: 0.2s;
    margin-left: 0.5rem;
    fill: #000000;
  }
  :hover {
    svg {
      transform: translateX(4px);
    }
  }
`;

const ButtonContainer = styled.div`
  padding-top: 1rem;
`;

const Hero = () => {
  //
  const {
    contact: { telephone },
  } = useSiteMetadata();

  return (
    <Section>
      <Grid>
        <div id="hero-grid-1">
          <HeroImage />
        </div>
        <div id="hero-grid-2">
          <CopyWrapper>
            <SectionHeader>
              <h1>Sustainable and rapid growth for your crops</h1>
            </SectionHeader>
            <HeroCopy>Supercharge your soil today with our range of well-tested products.</HeroCopy>
            <ButtonContainer>
              <PrimaryCTA to="/products">See product catalogue</PrimaryCTA>
              <SecondaryCTA href={`tel:${telephone}`}>
                Call to learn more
                <Chevron />
              </SecondaryCTA>
            </ButtonContainer>
          </CopyWrapper>
        </div>
      </Grid>
    </Section>
  );
};

export default Hero;
