import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { slugify } from '../../utils';
import { PrimaryLinkGatsby, ProductName as Name, OptionInfo, DicscountLabel } from '../../components';
import AddToCartButton from '../../components/AddToCartButton';
import { defaultTheme, breakpoints } from '../../styles';

const Wrapper = styled.div`
  text-align: center;
`;

const ImageSharp = styled(Img)`
  width: 100%;
  height: 100%;
  background: ${props => (props.pinned ? defaultTheme.productImgBgPinned : defaultTheme.productImgBg)};
  padding-top: 1.5rem;
  transition: 0.2s;
  &:hover {
    background: ${defaultTheme.productImgBgHover};
    transform: scale(1.05);
  }
`;

const ImageCell = styled.div`
  box-shadow: var(--elevation-1);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1.5rem;
  @media (min-width: ${breakpoints.large}px) {
    margin-bottom: 2rem;
  }
  :hover {
    box-shadow: var(--elevation-2);
  }
`;

const Price = styled(OptionInfo)`
  margin-bottom: 1rem;
`;

const LearnMore = styled(PrimaryLinkGatsby)`
  display: inline-block;
`;

const ProductName = styled(Name)`
  margin-bottom: 0.5rem;
`;

const ProductCardSimple = ({ data }) => {
  //
  const { title, images, variants } = data;
  const image = images[0].localFile.childImageSharp.fluid;
  const { price, compareAtPrice } = variants[0];

  const savings = (retailPrice, originalPrice) => {
    return Math.ceil(((Number(originalPrice) - Number(retailPrice)) / Number(originalPrice)) * 100);
  };

  return (
    <Wrapper>
      <Link to={`/products/${slugify(title)}`} aria-label={title}>
        <ImageCell>
          <ImageSharp fluid={image} imgStyle={{ objectFit: 'contain' }} />
          {compareAtPrice && Number(compareAtPrice) > 0 && (
            <DicscountLabel>SAVE {savings(price, compareAtPrice)}%</DicscountLabel>
          )}
        </ImageCell>
      </Link>
      <ProductName>{title}</ProductName>
      <Price>
        Starts at MYR <span>{price.slice(0, -3)}</span>
      </Price>
      <div style={{ marginBottom: '1rem' }}>
        <LearnMore to={`/products/${slugify(title)}`} aria-label={`Learn more about ${title}`}>
          LEARN MORE
        </LearnMore>
      </div>
      <AddToCartButton variants={variants} productName={title} />
    </Wrapper>
  );
};

export default ProductCardSimple;

ProductCardSimple.propTypes = {
  data: PropTypes.object.isRequired,
};
