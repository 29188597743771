import React from 'react';
import styled from 'styled-components';
import { SecondaryButtonGatsby as Button, SectionHeader, SectionCopy } from '../../components';
import HowImage from './HowImage';
import { breakpoints, defaultTheme } from '../../styles';

const Section = styled.section`
  border-bottom: 1px ${defaultTheme.hrOnDark} solid;
`;

const Grid = styled.div`
  #image {
    grid-area: image;
    padding: 5rem 1rem 0;
  }
  #copy {
    grid-area: copy;
    position: relative;
    padding: 5rem 2rem 5rem;
    > div {
      max-width: 520px;
      margin: 0 auto;
    }
  }
  display: grid;
  background: ${defaultTheme.black};
  grid-template-rows: 352px 1fr;
  grid-template-areas:
    'image'
    'copy';
  @media (min-width: ${breakpoints.medium}px) {
    grid-template-rows: 384px 1fr;
    padding: 10rem 0 5rem;
    #image {
      padding: 0 2rem 0 2rem;
    }
  }
  @media (min-width: 900px) {
    padding: 7.5rem 1rem 7.5rem 0;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'image copy';
    grid-template-rows: 1fr;
    #image {
      padding: 0;
    }
    #copy {
      padding: 0 3rem 0;
    }
  }
  @media (min-width: ${breakpoints.large}px) {
    padding: 7.5rem 1rem 7.5rem 0;
    #image {
      padding: 0 1rem 0 0;
    }
    #copy {
      display: grid;
      align-items: center;
      > div {
        margin: 0;
      }
    }
  }
`;

const How = () => {
  //
  return (
    <Section>
      <Grid>
        <div id="image">
          <HowImage />
        </div>
        <div id="copy">
          <div>
            <SectionHeader modifiers={['onDark']}>
              <h2>How we can help you</h2>
            </SectionHeader>
            <SectionCopy modifiers={['end', 'onDark']}>
              We supply natural, organic and chemical agricultural inputs - fertilizers, bio pesticides, bio stimulants,
              soil amendments, multi minerals and animal nutrients.
            </SectionCopy>
            <Button modifiers={['onDark']} to="/contact">
              Contact us to learn more
            </Button>
          </div>
        </div>
      </Grid>
    </Section>
  );
};

export default How;
