/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

const SVG = props => {
  return (
    <svg height="12px" viewBox="0 0 26.21 40" {...props}>
      <path d="M0,6.1,13.86,20,0,33.9,6.14,40,26.21,20,6.14,0,0,6.1" />
    </svg>
  );
};

export default SVG;
